import React from 'react'
import { StackProps, XStack, YStack } from 'tamagui'

import SectionSpinner from './SectionSpinner'
import SectionYStack from './SectionYStack'

const SectionChart = ({
  header,
  children,
  showBorder,
  noHorizontalPadding,
  isLoading,
  ...stackProps
}: {
  height: number | string
  showBorder?: boolean
  noHorizontalPadding?: boolean
  isLoading?: boolean
  header?: React.ReactNode
} & StackProps) => {
  if (isLoading) {
    return (
      <SectionSpinner
        borderColor="$hairline"
        borderTopWidth={showBorder ? 1 : 0}
        borderBottomWidth={showBorder ? 1 : 0}
        {...stackProps}
      />
    )
  }

  return (
    <SectionYStack
      rowGap={0}
      width="100%"
      noHorizontalPadding={noHorizontalPadding}
      {...stackProps}
    >
      {header ? (
        <XStack paddingBottom="$2" width="100%" columnGap="$4" rowGap="$1" flexWrap="wrap">
          {header}
        </XStack>
      ) : null}
      <YStack
        width="100%"
        flexGrow={1}
        flexShrink={1}
        borderColor="$hairline"
        borderTopWidth={showBorder ? 1 : 0}
        borderBottomWidth={showBorder ? 1 : 0}
      >
        {children}
      </YStack>
    </SectionYStack>
  )
}

export default SectionChart
