import { styled, YStack } from 'tamagui'

const SectionYStack = styled(YStack, {
  paddingHorizontal: '$3',
  columnGap: '$4',
  rowGap: '$1',
  variants: {
    noHorizontalPadding: {
      true: {
        paddingHorizontal: 0,
      },
    },
  },
})

export default SectionYStack
