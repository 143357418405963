import { styled, XStack } from 'tamagui'

const SectionXStack = styled(XStack, {
  paddingHorizontal: '$3',
  columnGap: '$6',
  rowGap: '$3', // matches Section.Grid when flexWrap is used
  variants: {
    noHorizontalPadding: {
      true: {
        paddingHorizontal: 0,
      },
    },
    // TODO: @earthtojake invert to isLarge
    isCompact: {
      true: {
        columnGap: '$3',
      },
    },
  },
})

export default SectionXStack
