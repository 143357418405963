import React from 'react'

import BodyText, { BodyTextProps } from './BodyText'

export type LabelTextProps = Omit<BodyTextProps, 'size' | 'isBold'>

const LabelText = ({ color = 'secondary', ...props }: LabelTextProps) => (
  <BodyText {...props} color={color} />
)

export default LabelText
