import { ProgressColor } from '@lyra/core/utils/progress'
import { Check, RotateCcw, X } from '@tamagui/lucide-icons'
import React from 'react'
import { Stack, StackProps, YStack } from 'tamagui'

import BodyText from '../BodyText'
import Heading from '../Heading'
import Spinner from '../Spinner'
import SectionYStack from './SectionYStack'

export type SectionSpinnerStatus = 'paused' | 'action' | 'pending' | 'complete' | 'error' | 'retry'

export type SpinnerProps = {
  status?: SectionSpinnerStatus
  title?: string
  label?: React.ReactNode
} & StackProps

const getColor = (status: SectionSpinnerStatus): ProgressColor => {
  switch (status) {
    case 'action':
    case 'paused':
      return 'primary'
    case 'pending':
    case 'complete':
    case 'error':
      return 'red'
    case 'retry':
      return 'secondary'
  }
}

const SIZE = 64

const SectionSpinner = ({ status = 'pending', title, label, ...stackProps }: SpinnerProps) => {
  const color = getColor(status)

  const isPaused = status !== 'pending' && status !== 'action'

  return (
    <SectionYStack
      {...stackProps}
      alignItems="center"
      justifyContent="center"
      position="relative"
      gap="$2"
    >
      <Stack width={SIZE} height={SIZE} alignItems="center" justifyContent="center">
        {status === 'retry' ? (
          <RotateCcw size={SIZE} strokeWidth={1} color="$secondaryText" />
        ) : (
          <Spinner isPaused={isPaused} size={SIZE} color={color} />
        )}
        {status === 'complete' ? (
          <Check
            style={{ position: 'absolute', marginTop: '5%' }}
            strokeWidth={1.5}
            size={SIZE / 2}
            color="$redText"
          />
        ) : status === 'error' ? (
          <X style={{ position: 'absolute' }} strokeWidth={1.5} size={SIZE / 2} color="$redText" />
        ) : null}
      </Stack>
      {title || label ? (
        <YStack gap="$1">
          {title ? (
            <Heading size="h3" textAlign="center">
              {title}
            </Heading>
          ) : null}
          {typeof label === 'string' ? (
            <BodyText color="secondary" textAlign="center">
              {label}
            </BodyText>
          ) : label ? (
            label
          ) : null}
        </YStack>
      ) : null}
    </SectionYStack>
  )
}

export default SectionSpinner
