import React from 'react'

import { SectionStackProps } from '.'
import SectionXStack from './SectionXStack'

export type SectionGridProps = {
  maxColWidth?: number | string
  minColWidth?: number | string
} & SectionStackProps

const SectionGrid = ({
  maxColWidth = 260,
  minColWidth = 120,
  children,
  ...stackProps
}: SectionGridProps) => {
  return (
    <SectionXStack
      {...stackProps}
      isCompact
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(auto-fit, minmax(${
          typeof minColWidth === 'number' ? minColWidth + 'px' : minColWidth
        }, ${typeof maxColWidth === 'number' ? maxColWidth + 'px' : maxColWidth}))`,
      }}
    >
      {children}
    </SectionXStack>
  )
}

export default SectionGrid
