import React from 'react'
import { StackProps } from 'tamagui'

import BodyText from '../BodyText'
import Heading from '../Heading'
import SectionYStack from './SectionYStack'

const SectionPageHeader = ({
  title,
  subtitle,
  ...stackProps
}: {
  title: React.ReactNode
  subtitle?: React.ReactNode
} & StackProps) => {
  return (
    <SectionYStack {...stackProps}>
      <Heading size="h2">{title}</Heading>
      {subtitle ? (
        <BodyText size="lg" color="secondary">
          {subtitle}
        </BodyText>
      ) : null}
    </SectionYStack>
  )
}

export default SectionPageHeader
