import { LyraAuthHeaders } from '../../constants/api'
import tryRequest, { RequestOptions } from '../tryRequest'
import {
  PrivateGetSubaccountParamsSchema,
  PrivateGetSubaccountResponseSchema,
} from '../types/private.get_subaccount'

export default async function fetchSubaccount(
  params: PrivateGetSubaccountParamsSchema,
  authHeaders: LyraAuthHeaders,
  options?: RequestOptions
): Promise<PrivateGetSubaccountResponseSchema> {
  return tryRequest<PrivateGetSubaccountParamsSchema, PrivateGetSubaccountResponseSchema>(
    '/private/get_subaccount',
    params,
    {
      ...options,
      authHeaders,
    }
  )
}
