import React from 'react'
import { forwardRef } from 'react'
import { FontSizeTokens, SizableText, SizableTextProps, useTheme } from 'tamagui'

import { getTextColorVar, HeadingSize, TextColor } from '../utils/text'

export type HeadingProps = {
  size?: HeadingSize
  color?: TextColor | 'cta'
} & Omit<
  SizableTextProps,
  'fontFamily' | 'fontSize' | 'weight' | 'letterSpacing' | 'size' | 'color'
>

const Heading = forwardRef<HTMLElement, HeadingProps>(
  ({ size = 'h4', color = 'primary', ...props }, ref) => {
    const sizeToken = ('$' + size) as FontSizeTokens
    const theme = useTheme()
    return (
      <SizableText
        {...props}
        ref={ref}
        fontFamily={size === 'h1' || size === 'display' ? '$display' : '$heading'}
        color={color !== 'cta' ? getTextColorVar(color) : undefined}
        size={sizeToken}
        style={
          color === 'cta'
            ? {
                background: `linear-gradient(${theme.red?.get()}, ${theme.amber?.get()})`,
                WebkitBackgroundClip: 'text',
                backgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }
            : null
        }
      />
    )
  }
)

Heading.displayName = 'Heading'

export default Heading
