import React from 'react'
import { Stack, StackProps, YStack } from 'tamagui'

import BodyText from '../BodyText'
import Heading from '../Heading'
import SectionXStack from './SectionXStack'

const SectionSubheader = ({
  title,
  subtitle,
  rightContent,
  ...stackProps
}: {
  title: React.ReactNode
  subtitle?: React.ReactNode
  rightContent?: React.ReactNode
} & StackProps) => {
  return (
    <SectionXStack {...stackProps} gap="$2" justifyContent="space-between">
      <YStack flexGrow={1} flexShrink={1} justifyContent="center" gap="$1">
        <Heading size="h4">{title}</Heading>
        {subtitle ? <BodyText color="secondary">{subtitle}</BodyText> : null}
      </YStack>
      {rightContent ? <Stack>{rightContent}</Stack> : null}
    </SectionXStack>
  )
}

export default SectionSubheader
