import useHover from '@lyra/core/hooks/useHover'
import isExternalURL from '@lyra/core/utils/isExternalURL'
import React from 'react'
import { Anchor, FontSizeTokens, XStack } from 'tamagui'

import { getTextColorVar, TextColor } from '../utils/text'
import { BodyTextProps } from './BodyText'
import Icon, { ArrowUpRight } from './Icon'

export type LinkProps = {
  label: React.ReactNode
  href?: string
  target?: string
  color?: Omit<TextColor, 'secondary'>
  isActive?: boolean
  isDisabled?: boolean
} & Omit<BodyTextProps, 'color' | 'children'>

const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      label,
      href,
      target,
      isActive: isActiveInput,
      isDisabled,
      size,
      color: colorInput = 'secondary',
      onPress,
      ...textProps
    },
    ref
  ) => {
    const { isHovering, handleHoverIn, handleHoverOut } = useHover()

    const isActive = isHovering || isActiveInput
    const color: TextColor = isDisabled
      ? 'secondary'
      : isActive
      ? 'primary'
      : (colorInput as TextColor)

    const isExternalHref = !!href && (isExternalURL(href) || target === '_blank')

    const sizeToken = ('$' + size) as FontSizeTokens

    return (
      <Anchor
        {...textProps}
        onHoverIn={handleHoverIn}
        onHoverOut={handleHoverOut}
        onPress={!isDisabled ? onPress : undefined}
        href={!isDisabled && href ? href : undefined}
        target={!isDisabled ? target : undefined}
        color={getTextColorVar(color)}
        fontFamily="$body"
        size={size ? sizeToken : undefined}
        letterSpacing={size ? sizeToken : undefined}
        textDecorationLine="none"
        cursor={isDisabled ? 'not-allowed' : 'pointer'}
        alignItems="center"
        display="inline-flex"
        ref={ref}
      >
        {label}
        {isExternalHref ? (
          <>
            <XStack width="2px" />
            <Icon
              size={12}
              color={getTextColorVar(color)}
              icon={<ArrowUpRight strokeWidth={3} />}
            />
          </>
        ) : null}
      </Anchor>
    )
  }
)

Link.displayName = 'Link'

export default Link
