import { DepositNetwork } from './chains'
import { YieldTokenAddresses } from './yield'

export const rswETHCMainnetAddresses: YieldTokenAddresses = {
  lyraInputToken: {
    address: '0xC419959850d49166C2d5250Ee89ff9910679D8c8',
    symbol: 'rswETH',
    decimals: 18,
  },
  lyraOutputToken: {
    address: '0x5bbef94dcee8f087D5146d2815bC4955C76B2794',
    symbol: 'rswETHC',
    decimals: 18,
  },
  mintConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        inputToken: {
          address: '0xFAe103DC9cf190eD75350761e95403b7b8aFa6c0',
          symbol: 'rswETH',
          decimals: 18,
        },
        connector: '0xF15d420bE7b27F1fA0D9487105658EdC3C0EA508',
        vault: '0x4BB4C3CDc7562f08e9910A0C7D8bB7e108861eB4',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        outputToken: {
          address: '0xE9A12fB15cC00b59867E4E2f0aCbdCebfd32b3d7',
          symbol: 'rswETHC',
          decimals: 18,
        },
        connector: '0x5da948DFf8dc37b9Dd4544bf4c738bD5725EdccC',
        vault: '0xeF7b53D59b5C6c4B5Aae3b2Fb9b1446EA087c7ce',
      },
    ],
  },
  redeemConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        inputToken: {
          address: '0xE9A12fB15cC00b59867E4E2f0aCbdCebfd32b3d7',
          symbol: 'rswETHC',
          decimals: 18,
        },
        connector: '0xb1178803A726e2077947754de9f2f0cbdA29A60F',
        vault: '0x96E47fC9c701E8EEF22Fa53e62dc3eBBDcCAEa73',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        outputToken: {
          address: '0xFAe103DC9cf190eD75350761e95403b7b8aFa6c0',
          symbol: 'rswETH',
          decimals: 18,
        },
        connector: '0x7447bbF1F7A8101fEC9c0DA5487dB607657A88A1',
        vault: '0x6Fde8a8194B6CF67ff33356448d06bD8f90C1eF1',
      },
    ],
  },
}

export const weETHCMainnetAddresses: YieldTokenAddresses = {
  lyraInputToken: {
    address: '0x7B35b4c05a90Ea5f311AeC815BE4148b446a68a2',
    symbol: 'weETH',
    decimals: 18,
  },
  lyraOutputToken: {
    address: '0xec68928bd83B2E52fF5A8e8c215B6ea72879F521',
    symbol: 'weETHC',
    decimals: 18,
  },
  mintConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        inputToken: {
          address: '0xcd5fe23c85820f7b72d0926fc9b05b43e359b7ee',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0xCc958F84DaF36d3eC20BcBee7E99C073B882efc3',
        vault: '0x8180EcCC825b692ef65FF099a0A387743788bf78',
      },
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0x35751007a407ca6feffe80b3cb397736d2cf4dbe',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0xBd282333710B9C7e33E8a37d027885A7C079Ae23',
        vault: '0x3FBFD80EF7591658d1D7DdEC067F413eFd6f985c',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        outputToken: {
          address: '0xcAe44C93f7B3b519Fc28f9d4F7Ae22dE770a907b',
          symbol: 'weETHC',
          decimals: 18,
        },
        connector: '0x945958CbA0081add5F9aef0baBB925b03847F2bF',
        vault: '0x76ee38B66A2BEF69E32fE070CFEc24Bf7F754Db6',
      },
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0x1cbbC18CB128AA470733eD29938Ab4878B0BEF20',
          symbol: 'weETHC',
          decimals: 18,
        },
        connector: '0xFE71489dD286561Eef7d2121BCA09090492d6fC0',
        vault: '0x76ee38B66A2BEF69E32fE070CFEc24Bf7F754Db6',
      },
    ],
  },
  redeemConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        inputToken: {
          address: '0xcAe44C93f7B3b519Fc28f9d4F7Ae22dE770a907b',
          symbol: 'weETHC',
          decimals: 18,
        },
        connector: '0x3f66F272d33B764960779a301c4183306ae50e10',
        vault: '0xe7cD9370CdE6C9b5eAbCe8f86d01822d3de205A0',
      },
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0x1cbbC18CB128AA470733eD29938Ab4878B0BEF20',
          symbol: 'weETHC',
          decimals: 18,
        },
        connector: '0x44ed9cE901B367B1EF9DDBD4974C82A514c50DEc',
        vault: '0x8D2ec8D3033568ff42E4b39aF3F700250B255d96',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        outputToken: {
          address: '0xcd5fe23c85820f7b72d0926fc9b05b43e359b7ee',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0x1a22ec8Af98c39075afDf671a1e52bb569F74215',
        vault: '0xf58fF1Adc4d045e712a6D91e69d93B4092516659',
      },
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0x35751007a407ca6feffe80b3cb397736d2cf4dbe',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0x6Ee9b6ad1c97AdeeD071fd5f349cE65f91e43333',
        vault: '0xf58fF1Adc4d045e712a6D91e69d93B4092516659',
      },
    ],
  },
}

export const rsETHCMainnetAddresses: YieldTokenAddresses = {
  lyraInputToken: {
    address: '0xc47e2E800a9184cFbD274AC1eeCcCDF942715dB7',
    symbol: 'rsETH',
    decimals: 18,
  },
  lyraOutputToken: {
    address: '0xd35bb8582809b4BDa4F8bCCE1bde48559f63eCbf',
    symbol: 'rsETHC',
    decimals: 18,
  },
  mintConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        inputToken: {
          address: '0xA1290d69c65A6Fe4DF752f95823fae25cB99e5A7',
          symbol: 'rsETH',
          decimals: 18,
        },
        connector: '0x388341d9E5A7D7d5accD738B2a31b0622E0c1b87',
        vault: '0x35d4D9bc79B0a543934b1769304B90d752691caD',
      },
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0x4186BFC76E2E237523CBC30FD220FE055156b41F',
          symbol: 'rsETH',
          decimals: 18,
        },
        connector: '0x59729095f131Ff2cb4c3F647EdcfE26eE8dB6C61',
        vault: '0x486936FB1CE805e8C46E71C69256e72f3f550d38',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        outputToken: {
          address: '0xF90b959ae8dfBa2DD793AD05176209835658362b',
          symbol: 'rsETHC',
          decimals: 18,
        },
        connector: '0xc83994744a346CEf7C9224E531A57a4607996142',
        vault: '0x50F18D1377B9DFFb7db6A9579B81C3EF27756d57',
      },
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0x8F5d8a65D98925E10BA83b5C1C5c3BE100f7591B',
          symbol: 'rsETHC',
          decimals: 18,
        },
        connector: '0xb3Fd0DEBaAAD6ac64813A4E5D651212ECEBfF4EB',
        vault: '0x50F18D1377B9DFFb7db6A9579B81C3EF27756d57',
      },
    ],
  },
  redeemConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        inputToken: {
          address: '0xF90b959ae8dfBa2DD793AD05176209835658362b',
          symbol: 'rsETHC',
          decimals: 18,
        },
        connector: '0xA72bc51f800127621d4Ab541E7Bb70B86Fe88F0F',
        vault: '0x4Dfa9966457EE49dCa3407f8DD484B739C048670',
      },
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0x8F5d8a65D98925E10BA83b5C1C5c3BE100f7591B',
          symbol: 'rsETHC',
          decimals: 18,
        },
        connector: '0x15144f3014898AbDAD0D5777bFB303132525FfeD',
        vault: '0x2e9E50b883cF3240bDC40d5496b914Da5d544B86',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        outputToken: {
          address: '0xA1290d69c65A6Fe4DF752f95823fae25cB99e5A7',
          symbol: 'rsETH',
          decimals: 18,
        },
        connector: '0x4984D046EF87F9bCA7AE86558C567b898C4f6acD',
        vault: '0x6DCA2Cb269b7618B1F0D6195B5f0eAF3641b7136',
      },
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0x4186BFC76E2E237523CBC30FD220FE055156b41F',
          symbol: 'rsETH',
          decimals: 18,
        },
        connector: '0x851e30395dcb2c8438cC245d7e28da7c7E23F870',
        vault: '0x6DCA2Cb269b7618B1F0D6195B5f0eAF3641b7136',
      },
    ],
  },
}

export const sUSDeBULLMainnetAddresses: YieldTokenAddresses = {
  lyraInputToken: {
    address: '0xb82d12742c3728a14eaa43ebe1c0d32bb62216eb',
    symbol: 'sUSDe',
    decimals: 18,
  },
  lyraOutputToken: {
    address: '0x0b4eD379da8eF4FCF06F697c5782CA7b4c3E505E',
    symbol: 'sUSDeBULL',
    decimals: 18,
  },
  mintConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        inputToken: {
          address: '0x9d39a5de30e57443bff2a8307a4256c8797a3497',
          symbol: 'sUSDe',
          decimals: 18,
        },
        connector: '0xfa8c07E28461eb7c65b33De024DB97eE4C052C97',
        vault: '0xE3E96892D30E0ee1a8131BAf87c891201F7137bf',
      },
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0x211cc4dd073734da055fbf44a2b4667d5e5fe5d2',
          symbol: 'sUSDe',
          decimals: 18,
        },
        connector: '0xAc0c4B009efF095AD90c43a3B6dF097e835B0c62',
        vault: '0x3c143EA5eBaB50ad6D2B2d14FA719234d1d38F1b',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        outputToken: {
          address: '0x1d080C689B930f9dEa69CB3B4Bc6b8c213DFC2ad',
          symbol: 'sUSDeBULL',
          decimals: 18,
        },
        connector: '0xD4ab8AB74327D4dcfaf6b4B27593FC38b2F8aF5F',
        vault: '0xbF7591de71cAf166269744A370379B0178E3c732',
      },
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0x81494d722DDceDbA31ac40F28daFa66b207f232B',
          symbol: 'sUSDeBULL',
          decimals: 18,
        },
        connector: '0x4211Ff84a54B455c28dEc6e2B15324bA445959a9',
        vault: '0xbF7591de71cAf166269744A370379B0178E3c732',
      },
    ],
  },
  redeemConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        inputToken: {
          address: '0x1d080C689B930f9dEa69CB3B4Bc6b8c213DFC2ad',
          symbol: 'sUSDeBULL',
          decimals: 18,
        },
        connector: '0x12fBD04CB103c596B78110C70eEDF16821CBfcAE',
        vault: '0x988ddDb1c7578aFae302143b1c5fda12fD486904',
      },
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0x81494d722DDceDbA31ac40F28daFa66b207f232B',
          symbol: 'sUSDeBULL',
          decimals: 18,
        },
        connector: '0xeB184dcae58290F5f3c5Ca1A32813ebd28b6079f',
        vault: '0x43b019139946466A010c936a85df14C17C4159c0',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        outputToken: {
          address: '0x9d39a5de30e57443bff2a8307a4256c8797a3497',
          symbol: 'sUSDe',
          decimals: 18,
        },
        connector: '0x687E1c385740841a1a30eA53bF272664EDC57D9b',
        vault: '0x12A3674207E345c79e1AD67F62e3988e6bb06287',
      },
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0x211cc4dd073734da055fbf44a2b4667d5e5fe5d2',
          symbol: 'sUSDe',
          decimals: 18,
        },
        connector: '0x82d599C4982301d3Ce26b2AdcB67602DFa0b9f23',
        vault: '0x12A3674207E345c79e1AD67F62e3988e6bb06287',
      },
    ],
  },
}

export const weETHBULLMainnetAddresses: YieldTokenAddresses = {
  lyraInputToken: {
    address: '0x7B35b4c05a90Ea5f311AeC815BE4148b446a68a2',
    symbol: 'weETH',
    decimals: 18,
  },
  lyraOutputToken: {
    address: '0xe48cdbe3A233Ea577c9ee6959801e7730e516d1A',
    symbol: 'weETHBULL',
    decimals: 18,
  },
  mintConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        inputToken: {
          address: '0xcd5fe23c85820f7b72d0926fc9b05b43e359b7ee',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0xCc958F84DaF36d3eC20BcBee7E99C073B882efc3',
        vault: '0x8180EcCC825b692ef65FF099a0A387743788bf78',
      },
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0x35751007a407ca6feffe80b3cb397736d2cf4dbe',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0xBd282333710B9C7e33E8a37d027885A7C079Ae23',
        vault: '0x3FBFD80EF7591658d1D7DdEC067F413eFd6f985c',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        outputToken: {
          address: '0xC7EE36E027272F11135792FaDE64D9365Cc583B5',
          symbol: 'weETHBULL',
          decimals: 18,
        },
        connector: '0x9A25A56ad60e3009bF0945233752eE0e445511f7',
        vault: '0xaDA48ab8705Eb3904e5FA65D5622cd237a2341FF',
      },
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0xC7EE36E027272F11135792FaDE64D9365Cc583B5',
          symbol: 'weETHBULL',
          decimals: 18,
        },
        connector: '0x49d446506D0f2db507AB4804563be9331BBc80E7',
        vault: '0xaDA48ab8705Eb3904e5FA65D5622cd237a2341FF',
      },
    ],
  },
  redeemConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        inputToken: {
          address: '0xC7EE36E027272F11135792FaDE64D9365Cc583B5',
          symbol: 'weETHBULL',
          decimals: 18,
        },
        connector: '0x50D46c3BB529276aDe59a6678C14302D6B61C853',
        vault: '0x62afad1Dc013A75eb3c1524ca8293C6de7760D89',
      },
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0xC7EE36E027272F11135792FaDE64D9365Cc583B5',
          symbol: 'weETHBULL',
          decimals: 18,
        },
        connector: '0x50D46c3BB529276aDe59a6678C14302D6B61C853',
        vault: '0x62afad1Dc013A75eb3c1524ca8293C6de7760D89',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        outputToken: {
          address: '0xcd5fe23c85820f7b72d0926fc9b05b43e359b7ee',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0x1a22ec8Af98c39075afDf671a1e52bb569F74215',
        vault: '0xf58fF1Adc4d045e712a6D91e69d93B4092516659',
      },
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0x35751007a407ca6feffe80b3cb397736d2cf4dbe',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0x6Ee9b6ad1c97AdeeD071fd5f349cE65f91e43333',
        vault: '0xf58fF1Adc4d045e712a6D91e69d93B4092516659',
      },
    ],
  },
}

export const weETHCSMainnetAddresses: YieldTokenAddresses = {
  lyraInputToken: {
    address: '0x7B35b4c05a90Ea5f311AeC815BE4148b446a68a2',
    symbol: 'weETH',
    decimals: 18,
  },
  lyraOutputToken: {
    address: '0x12ab0242b3e8d4502FebCED6C2d52fD23F7262af',
    symbol: 'weETHCS',
    decimals: 18,
  },
  mintConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        inputToken: {
          address: '0xcd5fe23c85820f7b72d0926fc9b05b43e359b7ee',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0xCc958F84DaF36d3eC20BcBee7E99C073B882efc3',
        vault: '0x8180EcCC825b692ef65FF099a0A387743788bf78',
      },
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0x35751007a407ca6feffe80b3cb397736d2cf4dbe',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0xBd282333710B9C7e33E8a37d027885A7C079Ae23',
        vault: '0x3FBFD80EF7591658d1D7DdEC067F413eFd6f985c',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        outputToken: {
          address: '0x91e3489da66eD36ebe0Be1013D87449447FD2bFa',
          symbol: 'weETHCS',
          decimals: 18,
        },
        connector: '0x41218E4A8EAb7f1e9F28f4Ce4f644834a4CD8045',
        vault: '0xdBd48e52c5E7F56B3c31F73688De91BD30109c79',
      },
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0xb7F56c1a952D3AE664A83971BFfa5c1706947dBD',
          symbol: 'weETHCS',
          decimals: 18,
        },
        connector: '0xA42088f1099944db222EB1C985cbb7e7afF04609',
        vault: '0xdBd48e52c5E7F56B3c31F73688De91BD30109c79',
      },
    ],
  },
  redeemConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        inputToken: {
          address: '0x91e3489da66eD36ebe0Be1013D87449447FD2bFa',
          symbol: 'weETHCS',
          decimals: 18,
        },
        connector: '0x1b882b9E87ABd7DD9B9b689Bee10Ed6a040033D0',
        vault: '0x790b0c8D071e57255E6d8b0092154c702AeA73dd',
      },
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0xb7F56c1a952D3AE664A83971BFfa5c1706947dBD',
          symbol: 'weETHCS',
          decimals: 18,
        },
        connector: '0xC6ea9524058a4E8B312B87cE3040F81ce9840ff1',
        vault: '0xdBd48e52c5E7F56B3c31F73688De91BD30109c79',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        outputToken: {
          address: '0xcd5fe23c85820f7b72d0926fc9b05b43e359b7ee',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0x1a22ec8Af98c39075afDf671a1e52bb569F74215',
        vault: '0xf58fF1Adc4d045e712a6D91e69d93B4092516659',
      },
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0x35751007a407ca6feffe80b3cb397736d2cf4dbe',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0x6Ee9b6ad1c97AdeeD071fd5f349cE65f91e43333',
        vault: '0xf58fF1Adc4d045e712a6D91e69d93B4092516659',
      },
    ],
  },
}

export const LBTCPSMainnetAddresses: YieldTokenAddresses = {
  lyraInputToken: {
    address: '0x36b5C126A3D7B25F6032653A0d18823Ee48a890e',
    symbol: 'LBTC',
    decimals: 8,
  },
  lyraOutputToken: {
    address: '0x5Fc48A32437Ff4BBab2A22646c3c9344ba003971',
    symbol: 'LBTCPS',
    decimals: 8,
  },
  mintConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        inputToken: {
          address: '0x8236a87084f8B84306f72007F36F2618A5634494',
          symbol: 'LBTC',
          decimals: 8,
        },
        connector: '0x457379de638CAFeB1759a22457fe893b288E2e89',
        vault: '0x76624ff43D610F64177Bb9c194A2503642e9B803',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        outputToken: {
          address: '0x367711f0377867b51Fe53e30F5125a9A31d3D50b',
          symbol: 'LBTCPS',
          decimals: 8,
        },
        connector: '0x2B9984F1F979708CE229A6EBE0aaFbA5073C2045',
        vault: '0x47beB31d2Dd8d9853F03B230c16bF6B827c7B020',
      },
    ],
  },
  redeemConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        inputToken: {
          address: '0x367711f0377867b51Fe53e30F5125a9A31d3D50b',
          symbol: 'LBTCPS',
          decimals: 8,
        },
        connector: '0xdb1c2F432e51824b33b9269C4b1Ff6190c1e5F35',
        vault: '0x52CB41109b637F03B81b3FD6Dce4E3948b2F0923',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        outputToken: {
          address: '0x8236a87084f8B84306f72007F36F2618A5634494',
          symbol: 'LBTC',
          decimals: 8,
        },
        connector: '0x52CB41109b637F03B81b3FD6Dce4E3948b2F0923',
        vault: '0x5eFC527B2640681289E31E1e29f94EA397b6c589',
      },
    ],
  },
}

export const LBTCCSMainnetAddresses: YieldTokenAddresses = {
  lyraInputToken: {
    address: '0x36b5C126A3D7B25F6032653A0d18823Ee48a890e',
    symbol: 'LBTC',
    decimals: 8,
  },
  lyraOutputToken: {
    address: '0xbCab1f8BbA323BC55EA8cfaC34edAcf8DBE92dD4',
    symbol: 'LBTCCS',
    decimals: 8,
  },
  mintConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        inputToken: {
          address: '0x8236a87084f8B84306f72007F36F2618A5634494',
          symbol: 'LBTC',
          decimals: 8,
        },
        connector: '0x457379de638CAFeB1759a22457fe893b288E2e89',
        vault: '0x76624ff43D610F64177Bb9c194A2503642e9B803',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        outputToken: {
          address: '0x5a27765DbE2476240B1265A305c2e3554fD3f341',
          symbol: 'LBTCCS',
          decimals: 8,
        },
        connector: '0xB0A797aa4E8A58a9C2cBF8477f686Fbe2C09D092',
        vault: '0x0da8090b6e3A25AfE78AB003E00f82DF8f161125',
      },
    ],
  },
  redeemConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        inputToken: {
          address: '0x5a27765DbE2476240B1265A305c2e3554fD3f341',
          symbol: 'LBTCCS',
          decimals: 8,
        },
        connector: '0x2D733e70A377FcFc249d273095250762A93F3820',
        vault: '0x3083B6140eF4916a315162C32b8AA9261572cc4b',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        outputToken: {
          address: '0x8236a87084f8B84306f72007F36F2618A5634494',
          symbol: 'LBTC',
          decimals: 8,
        },
        connector: '0x52CB41109b637F03B81b3FD6Dce4E3948b2F0923',
        vault: '0x5eFC527B2640681289E31E1e29f94EA397b6c589',
      },
    ],
  },
}
